<template>
  <div class="empty-layout">
    <slot />
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/media";

.empty-layout {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9168c8;

  @include media('sm') {
    align-items: flex-start;
    background: unset;
  }

  & > div {
    width: -webkit-fill-available;
  }
}
</style>
